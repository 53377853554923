import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:py-0 py-10">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-fill"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[150px] md:bottom-[100px] lg:bottom-[170px] md:text-start text-center text-white">
                    <div className="w-full md:w-1/2 text-center md:text-start p-4 ">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em] text-[#158CE3] ">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 text-[#000000]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
                <div className='md:w-[340px] md:h-[340px] h-[240px] w-[240px] z-10 absolute md:right-0 right-16 md:bottom-64 -bottom-20'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F27%20Years.png?alt=media&token=d128fb97-e6b4-478d-879b-ab93a80eacb3" alt="burble"
                        className='w-full h-full object-contain'
                    />
                </div>

            </div>
        </div>
    );
}
export default HeroVideo;